import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';
import dateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';
import { maskedCnpjCpf } from '../../../../shared/utils/Utils';

export const pedidoDinamicoService = {
  find,
  GetColumns
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(appliedFilters) {

  appliedFilters.dataInicio = appliedFilters.dataInicio.split('T')[0];
  appliedFilters.dataFim = appliedFilters.dataFim.split('T')[0];

  const response = await urlLinxCore.post('/Orcamento/Recuperar', appliedFilters)
    .then(response => {
      return response.data
    })
    .catch(error => {
      debugger;
      return error.response.data
    })
  return response;
}

const formatDocument = data => {
  return maskedCnpjCpf(data.value);
};

function GetColumns(matriz) {

  let colunas = [
    {
      dataField: 'empresaCnpj',
      caption: 'CNPJ da empresa',
      width: '130',
      visible: true,
    },  
    {
      dataField: 'pedidoEmissao',
      caption: 'Emissão',
      dataType: 'date',
      width: '130',
      visible: true,
    },
    {
      dataField: 'pedidoHora',
      caption: 'Hora',
      width: '130',
      visible: true,
    },
    {
      dataField: 'pedidoNumeroIntegracao',
      caption: 'Nº Pedido',
      width: '130',
      visible: true,
      groupIndex: 1,
    },
    {
      dataField: 'pedidoNumeroDocumento',
      caption: 'Nº Documento',
      width: '130',
      visible: true,
    },
    {
      dataField: 'pedidoOrigemVenda',
      caption: 'Origem',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pedidoStatusDescricao',
      caption: 'Status',
      width: '130',
      visible: true,
    },
    {
      dataField: 'integracaoStatusDescricao',
      caption: 'Status Integração',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pedidoMaquina',
      caption: 'Máquina',
      visible: false,
    },
    {
      dataField: 'pedidoCliente',
      caption: 'Cliente',
      visible: true,
    },	
    {
      dataField: 'pedidoClienteCpf',
      caption: 'Cliente CPF',
      visible: false,
    },
    {
      dataField: 'pedidoClienteTelefone',
      caption: 'Telefone',
      visible: false,
    },
    {
      dataField: 'pedidoClienteEmail',
      caption: 'Email',
      visible: false,
    },
    {
      dataField: 'pedidoClienteEndereco',
      caption: 'Endereço Entrega',
      visible: false,
    },
    {
      dataField: 'pedidoClienteBairro',
      caption: 'Bairro',
      visible: false,
    },
    {
      dataField: 'pedidoClienteNumero',
      caption: 'Endereço Número',
      visible: false,
    },
    {
      dataField: 'pedidoClienteCep',
      caption: 'CEP',
      visible: false,
    },
    {
      dataField: 'pedidoClienteMunicipio',
      caption: 'Município',
      visible: false,
    },
    {
      dataField: 'pedidoClienteComplemento',
      caption: 'Endereço Complemento',
      visible: false,
    },    
    {
      dataField: 'pedidoTaxaEntrega',
      caption: 'Taxa de Entrega',
      format: { precision: 2 },
      width: '130',
      visible: false,
      totalize: false,
    },
    {
      dataField: 'pedidoValorFrete',
      caption: 'Valor Frete',
      format: { precision: 2 },
      width: '130',
      visible: false,
      totalize: false,
    },
    {
      dataField: 'produtoCodigo',
      caption: 'Código Produto',
      width: '120',
      visible: true
    },
    {
      dataField: 'produtoDescricao',
      caption: 'Descrição Produto',
      minWidth: '200',
      visible: true
    },
    {
      dataField: 'produtoQuantidade',
      caption: 'Quantidade',
      format: { precision: 2 },
      width: '100',
      visible: true,
      totalize: true,
    },
    {
      dataField: 'produtoValorUnitario',
      caption: 'Unitário R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: true,
      totalize: true,
    },
    {
      dataField: 'produtoDesconto',
      caption: 'Desconto Orçamento R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: true,
      totalize: true,
    },
    {
      dataField: 'produtoAcrescimo',
      caption: 'Acréscimo Orçamento R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: true,
      totalize: true,
    },
    {
      dataField: 'produtoFrete',
      caption: 'Frete R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: true,
      totalize: true,
    },
    {
      dataField: 'produtoTaxaEntrega',
      caption: 'Taxa Entrega Item R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'produtoValorLiquido',
      caption: 'Líquido Orçamento R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: true,
      totalize: true,
    },
    {
      dataField: 'itemVendaDesconto',
      caption: 'Desconto Venda R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemVendaAcrescimo',
      caption: 'Acréscimo Venda R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemVendaLiquido',
      caption: 'Líquido Venda R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemVendaStatus',
      caption: 'Status na Venda',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pedidoPagamentoHyperlocal',
      caption: 'Pagamento Hyperlocal',
      width: '130',
      visible: true,
    }
  ];
  
  if (matriz)
    colunas[0].groupIndex = 1;
  
  return colunas;
}

export default urlLinxCore;
